import React from "react"
import { Box, Text, Flex } from "@sqymagma/elements"
import { theme } from '../../utils/themeGet'


export default (props) => {
  return (
    props && <ButtonSwitch {...props} />
  )
}

const ButtonSwitch = (props) => {
    const { mode, switchMode , opt1, opt2, width} = props
    
    return (
    <Box width={width ? width : "165px" }height="32px" bg="ui01" borderRadius="50px" border="1px solid #00000025" overflow="hidden"  position="relative"
        css={`{ box-shadow: inset 0 2px 0 #00000025 }`}
    >
        <Button switchMode={switchMode} {...mode}>{mode && mode.title}</Button>
        <Flex height="30px" alignItems="center" justifyContent="center">
            <Position switchMode={switchMode} >{opt1}</Position>
            <Position switchMode={switchMode} >{opt2}</Position>
        </Flex>
    </Box>
)}

const Button = ({ switchMode, color, title, switched }) => {
    return (
    <Flex bg={color ? color : 'brand02'} width="55%" borderRadius="50px" height="30px"
        alignItems="center" justifyContent="center" position="absolute"
        zIndex={2}
        css={`{ 
            cursor:pointer;
            border: 1px solid #00000025;
            box-shadow: 0px 1px 3px #00000070;
            right: ${switched ? "0%" : "45%"};
            transition: all 0.25s ${theme(`easingFn.easeOut`)};
        }`}
        onClick={()=>switchMode()}
    >
        <Text textStyle="table-row-bold" color="text06">
            {title}
        </Text>
    </Flex>
)}

const Position = ({ switchMode, children }) => {
    return (
        <Text 
            css={`{cursor:pointer}`}
            width={1/2} 
            textAlign="center" 
            textStyle="table-row-bold" 
            color="text01"
            onClick={()=>switchMode()}
        >
            {children}
        </Text>
)}