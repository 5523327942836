import React, {useState, useEffect} from 'react'
import { MaxWidth, Columns, Column, Text, Box, Flex } from '@sqymagma/elements';
import spainIsocodes from '../endpoints.json';
import GraphToggle from "../GraphToggle";
import LineChartItem from './LineChartItem';
import ApexCharts from 'apexcharts';

const LineCharts = _ => {
    const [ modeGraph, setModeGraph ] = useState(null)
    const [ showGuides, setShowGuides ] = useState(null)

    useEffect(() => {
        setModeGraph({
            title: "Lineal",
            switched: false,
            color: 'support03'
        })
        setShowGuides({
            title: "Mostrar guías",
            switched: false,
            color: 'support02'
        })
    }, [])

    const switchModeGraph = () => {
        setModeGraph({
            title: modeGraph.title === "Lineal" ? "Logarítmica" : "Lineal",
            switched: !modeGraph.switched,
            color: 'support03'
        })
    }
    const switchModeDuplAxis= () => {
        setShowGuides({
            title: showGuides.title === "Ocultar guías" ? "Mostrar guías" : "Ocultar guías",
            switched: !showGuides.switched,
            color: 'support02'
        })

        Object.values(spainIsocodes.ccaa).forEach((ccaa) => {
            ApexCharts.exec(`${ccaa}`, 'toggleSeries', '2 días')
        })
    }

    return(
        <>
        <LineChartsStickyHeader 
            modeGraph={modeGraph} switchModeGraph={switchModeGraph}
            showGuides={showGuides} switchModeDuplAxis={switchModeDuplAxis}
        />
        <MaxWidth>
            <Columns hs="s" vs="s" mb="l">
            { Object.keys(spainIsocodes.ccaa).map((ccaa, idx) => (
                    <Column key={idx} width={[1, null, 1/2, null, null, 1/3]}>
                        <LineChartItem 
                            isocode={ccaa} ccaa={spainIsocodes.ccaa[ccaa]} 
                            mode={modeGraph} showGuides={showGuides}
                        />
                    </Column>
            )) }
            </Columns>
        </MaxWidth>
        </>
    )
}
export default LineCharts;

    
const LineChartsStickyHeader = (props) => {
    const { modeGraph, switchModeGraph, showGuides, switchModeDuplAxis } = props
    return (
    <Box bg="ui07" minHeight="30px" width="100vw" py="xs" mb="l" position="sticky" top="49px" zIndex={999}>
        <MaxWidth>
            <Flex alignItems="center" justifyContent="space-between">
            <Text as="h2" color="white" textStyle="l">
                Evolución de casos confirmados por Comunidades 
            </Text>
            <Flex>
                <Box mr="s">
                    <GraphToggle 
                        mode={modeGraph} 
                        switchMode={switchModeGraph} 
                        opt1="Lineal" opt2="Logarítmica" 
                        width={'200px'}
                    />
                </Box>
                <Box>
                    <GraphToggle 
                        mode={showGuides} 
                        switchMode={switchModeDuplAxis} 
                        opt1="Mostrar guías" opt2="Ocultar guías" 
                        width={'200px'}
                        />
                </Box>
            </Flex>
            </Flex>
        </MaxWidth>
    </Box>
    )
}