import React, { useState, useEffect } from "react";
import JSONData from "../endpoints.json";
import {
  useGhosting,
  useFetchCCAAData,
  useGraph,
  useAnnotations,
} from "./hooks";
import { multipleLines } from "./utils";
import * as S from "./style";
import { Text, Box, Flex } from "@sqymagma/elements";
import { GraphContainer } from "../UIElements";
import { Redradix } from "../../IconSystem";

// ! Gatsby build fix
// import Chart from "react-apexcharts";
import Loadable from "@loadable/component";
const Chart = ({ options, series }) => (
  <>
    <LoadableChart options={options} series={series} />
  </>
);
// import react-apexcharts here
const LoadableChart = Loadable(() =>
  import("react-apexcharts/src/react-apexcharts")
);

/**
 * Se encarga de imponer las restricciones al Array de comunidades seleccionadas dependiendo de que estado nos encontremos.
 * - Si estamos en modo múltiples series, solo queremos un valor.
 * - Si estamos con una serie y existe el valor y solo hay un elemento, lo mantenemos.
 * - Si estamos con una serie y existe el valor y hay mças de un elemento, lo quitamos manteniendo el orden.
 * - Si no existe y el array no esta al limite, metemos el dato directamente.
 * - Si no existe y el array esta al limite, borramos el primer dato y lo metemos al final.
 * - Si es un estado no contemplado, el estado no se modifica.
 * @param {Array} state El estado actual de las comunidades seleccionadas
 * @param {Object} selectedSeries Las series seleccionadas y no seleccionadas por el usuario
 * @param {string} checkBoxValue La comunidad seleccionada por el usuario
 */

const formatState = (state, selectedSeries, checkBoxValue) => {
  let index = state.indexOf(checkBoxValue);
  if (multipleLines(selectedSeries)) {
    return [checkBoxValue];
  } else if (index !== -1 && state.length === 1) {
    return state;
  } else if (index !== -1) {
    return [...state.splice(0, index), ...state.slice(index + 1)];
  } else if (!state.includes(checkBoxValue) && state.length < 3) {
    return state.concat([checkBoxValue]);
  } else if (!state.includes(checkBoxValue) && state.length === 3) {
    const [, ...rest] = state;
    return rest.concat([checkBoxValue]);
  } else {
    return state;
  }
};

const ccaa = JSONData.ccaa;

const CCAAGraph = () => {
  const [ccaaData] = useFetchCCAAData();
  const [ghosting, setGhosting] = useState(false);
  const [selectedCCAA, setSelectedCCAA] = useState(() => {
    return Object.values(JSONData.ccaa).slice(0, 3);
  });
  const [selectedSeries, setSelectedSeries] = useState(() => {
    return {
      confirmed: true,
      deaths: false,
      recovered: false,
    };
  });

  // ! Test para resolver issue en build de gatsby - basado en GH/gatsbyjs/gatsby/issue#21475
  // const [graphData, setGraphData] = useGraph(selectedSeries, ccaaData, selectedCCAA, annotationsEnabled)
  // delara esta funcion sustituyendo annotationsEnabled por undefined
  const [graphData, setGraphData] = useGraph(
    selectedSeries,
    ccaaData,
    selectedCCAA,
    undefined
  );

  const [annotationsEnabled, setAnnotationsEnabled] = useAnnotations(
    setGraphData
  );
  useGhosting(ghosting, ccaaData, selectedCCAA, selectedSeries, graphData);

  const onChangeCCAACheckbox = (e) => {
    let checkBoxValue = e.target.value;
    setSelectedCCAA((state) => {
      return formatState(state, selectedSeries, checkBoxValue);
    });
  };

  const onChangeLegendCheckbox = (e) => {
    let checkBoxValue = e.target.value;
    setSelectedSeries((state) => {
      return { ...state, ...{ [checkBoxValue]: !state[checkBoxValue] } };
    });
  };

  useEffect(() => {
    if (multipleLines(selectedSeries)) {
      setSelectedCCAA((state) => {
        return [state[state.length - 1] || JSONData.ccaa["ES-MD"]];
      });
    }
  }, [selectedSeries, setSelectedCCAA]);

  return (
    <GraphContainer overflow="hidden">
      <Flex
        p={["s", "20px"]}
        pb="0"
        justifyContent={["space-between"]}
        flexDirection={["column", null, "row"]}
        mb={["xs", "s"]}
      >
        <Text as="h2" color="brand02" textStyle="s" caps mb={["xs", "s", "m"]}>
          Datos diarios por comunidades
        </Text>
        <Flex flexWrap="wrap">
          <S.AdvancedOption
            selected={!ghosting}
            w={["35%", "65%"]}
            enabled={multipleLines(selectedSeries)}
          >
            <S.AdvancedOptionTitle selected={!ghosting}>
              Todos
            </S.AdvancedOptionTitle>
            <S.AdvancedOptionTitle selected={ghosting}>
              Seleccionados
            </S.AdvancedOptionTitle>
            <input
              type="checkbox"
              checked={ghosting}
              onChange={() => setGhosting((ghosting) => !ghosting)}
            />
          </S.AdvancedOption>

          <S.AdvancedOption selected={!annotationsEnabled} w={["50%", "50%"]}>
            <S.AdvancedOptionTitle selected={!annotationsEnabled}>
              Con anotaciones
            </S.AdvancedOptionTitle>
            <S.AdvancedOptionTitle selected={annotationsEnabled}>
              Sin anotaciones
            </S.AdvancedOptionTitle>
            <input
              type="checkbox"
              checked={annotationsEnabled}
              onChange={() =>
                setAnnotationsEnabled(
                  (annotationsEnabled) => !annotationsEnabled
                )
              }
            />
          </S.AdvancedOption>
        </Flex>
      </Flex>

      <Flex
        p={["s", "20px"]}
        pt="0"
        position="relative"
        flexDirection={["column", null, "row"]}
        width={1}
      >
        <Flex flexDirection="column" width={[1, null, 3 / 4]}>
          <S.SeriesOptions>
            <S.SeriesOption selected={selectedSeries.confirmed} confirmed>
              <input
                type="checkbox"
                onChange={onChangeLegendCheckbox}
                value="confirmed"
              />
              Confirmados
            </S.SeriesOption>
            <S.SeriesOption selected={selectedSeries.deaths} deaths>
              <input
                type="checkbox"
                onChange={onChangeLegendCheckbox}
                value="deaths"
              />
              Fallecidos
            </S.SeriesOption>
            <S.SeriesOption selected={selectedSeries.recovered} recovered>
              <input
                type="checkbox"
                onChange={onChangeLegendCheckbox}
                value="recovered"
              />
              Recuperados
            </S.SeriesOption>
          </S.SeriesOptions>

          <Box mt={["l", "l", "l", 0]}>
            <S.ChartWrapper>
              <Chart
                options={graphData.options}
                series={graphData.series}
                height="400px"
              />
            </S.ChartWrapper>
          </Box>
        </Flex>

        <Flex
          p={[0, null, "s"]}
          position="relative"
          overflow="hidden"
          flexDirection="column"
          maxHeight={["none", null, "75vh"]}
          width={[1, null, 1 / 4]}
          mt={[0]}
          borderLeft={'1px solid #f2f2f2'}
        >
          <Text textStyle="s" color="brand02" mb="xs">
            Selecciona Comunidades
          </Text>
          <Flex
            overflow={["visible", null, "auto"]}
            column={["row", null, "column"]}
            flexWrap="wrap"
          >
            {Object.entries(ccaa).map(([, value], idx) => {
              return (
                <Flex width={[1 / 2, 1 / 3, 1]} key={idx}>
                  <S.ProvinceOption
                    key={value}
                    selected={selectedCCAA.includes(value)}
                  >
                    <input
                      type="checkbox"
                      value={value}
                      checked={selectedCCAA.includes(value)}
                      onChange={onChangeCCAACheckbox}
                    />
                    {value}
                  </S.ProvinceOption>
                </Flex>
              );
            })}
          </Flex>
        </Flex>
      </Flex>
      <RedradixFooter />
    </GraphContainer>
  );
};

export default CCAAGraph;

const RedradixFooter = (_) => (
  <Box
    mt="xs"
    bg="ui07"
    p="xs"
    pb="12px"
    css={`
       {
        border-top: 1px solid #dddee0;
      }
    `}
  >
    <Text textStyle="tiny" color="text05" as="p" textAlign="right" px="xs">
      En colaboración con{" "}
      <a href="https://redradix.com/" target="_blank" rel="noopener noreferrer">
        <Redradix width="65px" fill="text05" />
      </a>
    </Text>
  </Box>
);
