import styled from "styled-components"
import { theme } from "../../../utils/themeGet"

export const GraphContainer = styled.div`
  padding: 20px;
  border: 1px solid #DDDEE0;
  border-radius: ${theme("radii.xs")};
  background: ${theme("color.primaryBackground")};
`;

export const ChartWrapper = styled.div`
  flex: 0 0 70%;
  .apexcharts-toolbar{
    z-index: 1;
    margin-top: -20px;

    @media(max-width: 600px) {
      margin-top: 0;
    }
  }
  .apexcharts-toolbar > div {
    width: 32px;
    height: 32px;
    transform: scale(.9);
    margin: 0 auto;

    .pan-icon {
      width: 24px;
    }
    .download-icon{
      transform: scale(1);
    }

    @media(max-width: 600px) {
      transform: scale(1);
      width: 24px;
      height: 24px;
    }
  }
  
  
  .apexcharts-canvas .apexcharts-element-hidden {
    display: none;
  }

  .apexcharts-pan-icon {
    margin-left: 2px;
  }

  .apexcharts-pan-icon img {
    transform: scale(1.2);
  }

  .apexcharts-reset-icon svg {
    position: relative;
    top: 1px;
  }

  .apexcharts-reset-icon svg {
    transform: scale(1.1);
  }

  .apexcharts-menu-icon svg {
    transform: scale(1.1);
    position: relative;
    top: 1px;
  }

  .apexcharts-toolbar > div img {
    height: 100%;
    width: 100%;
  }

  .apexcharts-tooltip.apexcharts-theme-light {
    background-color: rgba(255, 255, 255, 0.65);
  }

  .tooltip {
    padding: 10px;
    border-radius: 3px;
    border: 1px solid ${theme("color.gray04")};
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    min-width: 6rem;
    background-color: rgba(255, 255, 255, 0.65);

    .tooltip-title {
      font-size: 10px;
      line-height: 12px;
      color: #101010;
      padding-bottom: 5px;
      display: flex;
      align-items: center;

      .tooltip-icon {
        height: 1em;
        width: 1em;
        display: inline-block;
        margin-right: 5px;
        position: relative;
        top: -1px;
      }
    }

    .tooltip-element {
      &:not(:last-child) {
        margin-bottom: 8px;
      }

      .tooltip-color {
        height: 8px;
        width: 8px;
        border-radius: 50%;
        background-color: red;
        display: inline-block;
        margin-right: 5px;
      }

      .tooltip-ccaa {
        font-size: 12px;
        line-height: 14px;
        font-family: ${theme("fontFamily.secondary")};
      }

      .tooltip-data {
        font-weight: 800;
        font-size: 16px;
        line-height: 20px;
        font-family: ${theme("fontFamily.primary")};
        display: block;
        color: ${theme("color.gray04")};
        margin-left: 20px;
        margin-top: 3px;
      }
    }
  }

  .apexcharts-annotation {
    font-size: 5rem !important;
    color: red !important;
  }

  @media(max-width:500px) {
    .apexcharts-toolbar > div {
      width: 30px;
      height: 30px;
      transform: scale(1);
    }
  }
`

export const GraphContent = styled.div`
  display: grid;
  grid-template-columns: 70% 1fr;
  grid-auto-columns: 1fr;
  padding-top: 30px;
  position: relative;
`

export const SeriesOptions = styled.div`
  position: relative;
  z-index: 1;
  cursor: pointer;
  height: 100%;
  display: flex;
  flex-wrap: wrap;
  border-radius: ${theme("radii.xs")};

  input {
    visibility: hidden;
    height: 13px;
    width: 13px;
  }
`

export const SeriesOption = styled.label`
  height: 30px;
  display: flex;
  align-items: center;
  padding-left: 10px;
  padding-right: 7px;
  font-family: ${theme("fontFamily.secondary")};
  font-size: 12px;
  line-height: 14px;
  position: relative;
  cursor: pointer;
  border-radius: ${theme("radii.xs")};
  border: 1px solid ${theme("color.gray03")};
  opacity: 0.25;
  margin-bottom: 4px;

  &:not(:last-child) {
    margin-right: 5px;
  }

  ${p => (
    p.selected && `
      opacity: 1;
      background: ${
        p.confirmed ? 'rgba(251, 225, 92, 0.3);' : 
        p.deaths ? 'rgba(250, 0, 103, 0.3);' : 
        p.recovered ? 'rgba(25, 233, 158, 0.3);' : ''
      }
    `
  )}

  &:before {
    content: '';
    position: absolute;
    left: 7px;
    width: 10px;
    height: 10px;
    background: ${p => {
      if (p.confirmed) return p.theme.color.secondaryOrange06
      if (p.deaths) return p.theme.color.secondaryRed08
      if (p.recovered) return p.theme.color.secondaryGreen07
    }};
    border-radius: 50%;
    border: 2px solid ${theme("color.gray07")};
  }
`

export const Title = styled.p`
  ${theme("textStyle.dashboardTitle")};
  color: ${theme("color.secondaryRed")};
  font-style: normal;
  font-weight: 800;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  ${p => p.uppercase && 'text-transform: uppercase;'}
`

export const Sidebar = styled.div`
  flex: 0 0 30%;
  padding: 10px;
  position: relative;
  top: -17px;
`;

export const ProvinceOptions = styled.div`
  height: 100%;
  overflow: scroll;
  position: absolute;
  width: 100%;
`

export const ProvinceOption = styled.label`
  display: block;
  font-family: ${theme("fontFamily.secondary")};
  font-size: 12px;
  line-height: 14px;
  padding: 7px 10px;
  position: relative;
  cursor: pointer;
  ${p => (
    p.selected && `
      font-weight: 800;
    `
  )}

  input {
    visibility: hidden;
    height: 13px;
    width: 13px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 10px;
    left: 0;
    width: 10px;
    height: 10px;
    background: ${theme("color.gray04")};
    border-radius: 50%;
    border: 2px solid ${theme("color.gray06")};
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    opacity: 0.2;

    ${p => (
      p.selected && `
        border-color: ${p.theme.color.gray05};
        background: ${p.theme.color.secondaryGreen05};
        opacity: 1;
      `
    )}
  }
  @media(max-width: 500px) {
    font-size: 11px;
    padding: 4px 0;
    margin-right: 8px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

export const AdvancedOption = styled.label`
  background-color: #fff;
  border-radius: 50px;
  border: 1px solid #00000025;
  height: 30px;
  position: relative;
  display: flex;
  align-items:center;
  box-shadow: inset 0 2px 0 #00000025;
  cursor: pointer;
  width: fit-content;
  margin-right: 1rem;
  margin-bottom: .5rem;
  ${p => p.enabled && `
  pointer-events: none;
  color: #2C2E32;
  opacity: 0.5;
  font-family: 'Work Sans',
  
  span {
    color: #000000;
    opacity: .2;
    } 
  `}

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    background-color: ${theme("color.secondaryRed")};
    height: 100%;
    width: ${p => p.w[0]};
    border-radius: 50px;
    position: absolute;
    z-index: 0;
    top: 0;
    transition: .3s all ease;
    box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.25);

    ${p => p.enabled && `
      background-color: ${p.theme.color.gray03};
    `}
  }
  
  ${p => p.selected && (`
    &:before {
      left: ${p.w[0]};
      width: ${p.w[1]};
    }
  `)}

  input {
    visibility: hidden;
    width: 0;
  }

  &:last-child {
    margin-right:0;
  }
`

export const AdvancedOptionTitle = styled.span`
  z-index: 1;
  transition: .3s all ease;
  font-size: 12px;
  font-family: ${theme("fontFamily.primary")};
  font-weight: 600;
  padding: 12px;
  ${p => !p.selected && `color: #fff;`}

  &:first-child {
    padding-right: 15px;
  }
`